
import {
    ExclamationCircleOutlined,
} from '@ant-design/icons-vue';
export default {
    components: {
        ExclamationCircleOutlined
    },
    props: {
        show: Boolean,
        postal_code_loading: Boolean,
        query_address_loading: Boolean,
        form: Object,
        rules: Object,
        postal_code_datas: Array,
        countries: Array,
        countryFilter: Function,
        handlePhone: Function,
        handlePaste: Function,
        analyzePostalCode: Function,
        postalCodeSelectChanged: Function,
        test_rates: Function
    },
    computed: {
        address() {
            return this.form ? this.form : {}
        },
        showBook: {
            get() {
                return this.show
            },
            set(val) {
                this.$emit("update:show", val);
            }
        }
    }
}
